import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {delay, dematerialize, materialize, mergeMap} from 'rxjs/operators';
// LIMA
import licenseMockData from '../../assets/mock/LIMA/LicenseGetForUserResponse.json';
import licenseOverrideSaveResponse from '../../assets/mock/LIMA/LicenseOverrideResponse.json';
import deviceGetForUserResponse from '../../assets/mock/LIMA/DeviceGetForUserResponse.json';
import pruneLicenseUsageResponse from '../../assets/mock/LIMA/PruneLicenseUsageResponse.json';
import deviceUnregisterForLicenseResponse from '../../assets/mock/LIMA/DeviceUnregisterForLicenseResponse.json';

// AUA
// import extendedSearchMockData from '../../assets/mock/AUA/UserExtendedSearchRespone.json';
// import userSetProperties from '../../assets/mock/AUA/UserPropertySetResponse.json';
// import userGetMockData from '../../assets/mock/AUA/UserGet.json';

// PROS
// import prosGetProduct from '../../assets/mock/PROS/ProductGetForUserResponse.json';

// OSC
// import couponQuickSearchFail from '../../assets/mock/OSC/coupon_quicksearch_fail.json';

// import couponQuickSearchSuccess from '../../assets/mock/OSC/coupon_quicksearch_success.json';


// import couponSearchDetail from '../../assets/mock/OSC/coupons_found.json';

@Injectable()
export class MockRequestsInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(() => {

      // const licenseResponseBody = licenseMockData;
      // const extendedSearchResponseBody = extendedSearchMockData;

      // console.log(request.url);
      //
      // if (request.url.endsWith('/osc/v1/coupon/search') && request.method === 'POST') {
      //   return of(new HttpResponse({status: 200, body: couponQuickSearchSuccess}));
      // }

      /*
      if (request.url.endsWith('/osc/v1/coupon/search') && request.method === 'POST') {
        return of(new HttpResponse({status: 200, body: couponQuickSearchSuccess}));
      }
       */

      // PROS: licenseOverride
      // if (request.url.endsWith('/osc/v1/coupon/lookUp') && request.method === 'POST') {
      //     return of(new HttpResponse({status: 200, body: couponsFound}));
      // }

      // LIMA: licenseOverride
      // if (request.url.endsWith('/lima/v1/licenseOverride/save') && request.method === 'POST') {
      //     return of(new HttpResponse({status: 200, body: userSetProperties}));
      // }

      // LIMA: get license for user
      // if (request.url.endsWith('/lima/v1/license/getForUser') && request.method === 'POST') {
      //     return of(new HttpResponse({status: 200, body: licenseResponseBody}));
      // }

      // // //
      // // // LIMA: get devices for user
      // if (request.url.endsWith('/lima/v1/device/getForUser') && request.method === 'POST') {
      //     return of(new HttpResponse({status: 200, body: deviceGetForUserResponse}));
      // }

      // if (request.url.endsWith('/lima/v1/license/pruneLicenseUsage') && request.method === 'POST') {
      //     return of(new HttpResponse({status: 200, body: pruneLicenseUsageResponse}));
      // }
      //
      // if (request.url.endsWith('/lima/v1/license/pruneAllLicenseUsagesForUser') && request.method === 'POST') {
      //     return of(new HttpResponse({status: 200, body: pruneAllLicenseUsageResponse}));
      // }
      //
      // if (request.url.endsWith('/lima/v1/device/unregisterForLicenses') && request.method === 'POST') {
      //     return of(new HttpResponse({status: 200, body: deviceUnregisterForLicenseResponse}));
      // }

      // AUA: Set user properties
      // if (request.url.endsWith('/aua/v1/user/setProperties') && request.method === 'POST') {
      //     return of(new HttpResponse({status: 200, body: userSetProperties}));
      // }

      // // AUA: Filter for user
      // if (request.url.endsWith('/aua/v1/user/extendedSearch') && request.method === 'POST') {
      //     return of(new HttpResponse({status: 200, body: extendedSearchResponseBody}));
      // }
      //
      // // AUA: Filter for user
      // if (request.url.endsWith('/aua/v1/user/get') && request.method === 'POST') {
      //     return of(new HttpResponse({status: 200, body: userGetMockData}));
      // }
      //
      // // PROS: Filter for product
      // if (request.url.endsWith('/pros/v1/product/getForUser') && request.method === 'POST') {
      //     return of(new HttpResponse({status: 200, body: prosGetProduct}));
      // }

      // pass through any requests not handled above
      return next.handle(request);
    }))
      .pipe(materialize())
      .pipe(delay(500))
      .pipe(dematerialize());
  }
}
